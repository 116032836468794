<template>
<div>

  <template>
    <div id="breadcrumb-alignment" class="mb-1">
      <div class="d-flex justify-content-start breadcrumb-wrapper">
        <b-breadcrumb
          :items="breadCrumb()"
        />
      </div>
    </div>
  </template>

  <b-row class="match-height">
    <b-col
      lg="12"
      md="12"
    >
   
    <b-card
      title="Edit Location">
      <b-alert
        v-model="showDismissibleAlert"
        variant="danger"
        dismissible
        class="mb-1"
      >
        <div class="alert-body">
          {{error_message}}
        </div>
      </b-alert>
      
      <b-form @submit="formSubmit">
        
        <b-row>
          
          <b-col md="12">

            <b-row>
              <b-col md="12">
                <b-form-group
                  label="Name"
                  class="required"
                >
                  <b-form-input
                    placeholder="2 to 50 characters"
                    v-model="form.name"
                  />
                </b-form-group>
              </b-col>

              
            </b-row>

            <b-row>
              <b-col md="12" >
                
                <b-form-group
                  label="Type"
                  class="required"

                >
                  <v-select
                    v-model="form.type"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    multiple                    
                    :options="types"
                    placeholder="Select"
                    @input="nfcCheck"
                    :close-on-select=false
                    :clear-on-select=false
                    class="multiselect_muliple_options"
                  />
                    
                </b-form-group>
              </b-col>

            </b-row>



            <b-row v-if="form.show_nfc_tag" class="mb-2">
              
              <b-col md="12">
                <b-form-group
                  label="NFC Tag"
                  class="required"
                >
                  <b-form-input
                    
                    placeholder="upto 50 characters"
                    v-model="form.nfc_tag"
                  
                  />
                </b-form-group>
              </b-col>

            </b-row>

            <b-row v-if="form.type.indexOf('Toilet') >= 0" class="mb-2">
              
              <b-col md="12">
                <b-form-group
                  label="Toilet Type"
                  class="required mb-0"
                >
                  <div class="demo-inline-spacing">
                    <b-form-radio
                      class="mb-0 mt-1"
                      
                      name="some-radios"
                      value="male"
                      v-model="form.toilet_type"
                    >
                      Male Toilet
                    </b-form-radio>

                    <b-form-radio
                      class="mb-0 mt-1"
                      
                      name="some-radios"
                      value="female"
                      v-model="form.toilet_type"
                    >
                      Female Toilet
                    </b-form-radio>

                    <b-form-radio
                      class="mb-0 mt-1"
                      
                      name="some-radios"
                      value="handicapped"
                      v-model="form.toilet_type"
                    >
                      Handicap Toilet
                    </b-form-radio>

                    <b-form-radio
                      class="mb-0 mt-1"
                      
                      name="some-radios"
                      value="other"
                      v-model="form.toilet_type"
                    >
                      Other
                    </b-form-radio>

                  </div>
                </b-form-group>
              </b-col>

            </b-row>

            

            
            
            
            <b-row>
              <b-col>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="warning"
                  class="mr-1"
                >
                  Submit
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  @click="$router.push({ name:'site-locations',params:{site_id: form.site_id} })"
                >
                  Cancel
                </b-button>
              </b-col>
          </b-row>


          </b-col>
        </b-row>

      </b-form>
    </b-card>
    </b-col>
  </b-row>

  
</div>
</template>

<script>

import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BAlert,BFormSelect,BFormSelectOption,BFormText,BInputGroupPrepend,BInputGroup,BMedia,BAvatar,BTable,BModal, BFormRadio,BBreadcrumb
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { GET_API, POST_API } from "../../../store/actions.type"
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import vSelect from 'vue-select';
export default {
  components: {
    BAlert,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormSelect,
    BFormSelectOption,
    BFormText,
    BInputGroupPrepend,
    BInputGroup,
    BMedia,
    BAvatar,
    BTable,
    BModal,
    VueCropper,
    BFormRadio,
    vSelect,
    BBreadcrumb
  },
  directives: {
    Ripple,
  },
  data() {
    return {

      
      error_message:null,
      showDismissibleAlert:false,

      form :{
        toilet_type:'',
        name : '',
        type : [],
        nfc_tag:'',
        id:'',
        show_nfc_tag : false,
        site_id:'',
      },
      types:[
        'QI','Periodic Task','NFC','Toilet','Other',
      ]
      
    }
  },
  methods : {
    formSubmit(e){
      e.preventDefault();

      return this.$store.dispatch(POST_API, {
           data:{
             items:this.form
           },
           api: '/api/update-sites-location'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'Record Updated Successfully.',
                  showConfirmButton: false,
                  timer: 1500
                }).then((result) => {
                    // this.$router.go(-1);
                    this.$router.push({ name:'site-locations',params:{site_id: this.form.site_id} })
                });
                
            }
        });
      
    },
    locationDetail(){
      return this.$store.dispatch(POST_API, {
           data:{
             id:this.$route.params.id
           },
           api: '/api/location-detail'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                this.item = this.$store.getters.getResults.data;
                
                this.form.name = this.item.name;
                this.form.type = this.item.type;
                this.form.nfc_tag = this.item.nfc_tag;

                this.form.show_nfc_tag = this.item.nfc_tag == null ? false: true; 
                this.form.address = this.item.address ? this.item.address : '';

                this.form.site_id = this.item.site;
                this.form.id = this.item._id;
                this.form.toilet_type = this.item.toilet_type;
                return this.form;
            }
        });
    },
    nfcCheck(){
      if(this.form.type.indexOf('NFC') >= 0){
        this.form.show_nfc_tag = true;
        //this.form.nfc_tag = '';
      }else{
        this.form.show_nfc_tag = false;
        this.form.nfc_tag = '';
      }

      if(this.form.type.indexOf('Toilet') < 0){
        this.form.toilet_type = '';
      }
    },
    breadCrumb(){
      var item = [{
        to:{name:'client-dashboard'},
        text: 'Dashboard',
      },{
        to: null, // hyperlink
        text: 'Project Site' // crumb text
      },
      {
        to: {name:'job-sites'},
        text: 'Sites', // crumb text
      },
      {
        to: {name:'site-locations',params:{site_id: this.form.site_id}}, // hyperlink
        text: 'Locations', // crumb text
        
      },
      {
        to: null, // hyperlink
        text: 'Edit Location', // crumb text
        active:true
        
      }];
      return item;
    }

  },
  mounted(){
    this.locationDetail();
  }
}
</script>
